import React from "react"
import Link from "gatsby-link"

export default function CopyLeftImageRightHome(props) {
  return (
  	<section className="section-404">
	  	<div className="container">
	  	  <h1><span role="img" aria-label="man face-palming">🤦🏼‍♂</span> Go <Link to="/">home</Link>, you're drunk.</h1>
	  	</div>
  	</section>
  	)
}