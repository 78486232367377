import React from "react"
import SEO from "../components/seo"
import Section404 from "../components/404"

class FourOFour extends React.Component {
    componentDidMount() {
      
    }

    render() {
        return(
          <div>
              <SEO title="Page not found" description="" />
              <Section404/>
          </div>
        )
    }
}

export default FourOFour